* {
  --color-white: #f3e3e3;
  --color-brown: #443c3c;
  --color-text: var(--color-brown);
  --piano-color-primary: #f5f5f5;
  --piano-color-secondary: #373737;
  --piano-color-active: #e7c8c8;
  --piano-color-isKey: #c47575;
  --ui-border-radius: 8px;
  --piano-key-white-height: 24em;
  --piano-key-margin: -1.34em; /* TODO: --piano-key-white-height / 18 and negated round up*/
  --piano-key-white-width: calc(var(--piano-key-white-height) / 4);
  --piano-key-black-height: calc(var(--piano-key-white-height) / 1.5);
  --piano-key-black-width: calc(var(--piano-key-black-height) / 6);
  --piano-key-font-size: 20px;
  --text-size-default: 15px;
  --text-size-h2: 32px;
  --text-size-h3: 20px;
  --container-padding: 0.75em 0.75em 0 0.75em;
  --scale-view-item-margin: 2.5px;
  --guitar-scale: 1;
  --scale-view-key-padding: 6px 13px;
  --scale-view-key-padding: 6px 13px;
  --instruments-svg-size: 3rem;
}

.App {
  display: flex;
  /* width: 100vw; */
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #443c3c;
  color: var(--color-text);
  font-family: 'Roboto Mono', monospace;
  font-family: 'Open Sans', sans-serif;
}

.wrapper {
  background: #f3e3e3;
  border-radius: 20px;
  max-width: 1100px;
  padding: 26px;
  margin: 1rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--container-padding);
}

.main {
  text-align: center;
}

header h2 {
  font-size: var(--text-size-h2);
  margin-top: 0.25em;
}

.instruments {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Responsive code */

@media only screen
  and (min-width: 320px)
  and (max-width: 360px) {
  * {
    --piano-key-white-height: 9em;
    --piano-key-margin: -0.5em;
    --piano-key-font-size: 12px;
    --text-size-default: 12px;
    --text-size-h2: 26px;
    --text-size-h3: 18px;
    --container-padding: 0.75em 0.75em 0 0.75em;
    --scale-view-item-margin: 2.5px;
  }

  .instruments {
    margin-left: 8px;
  }
}

@media only screen
  and (min-width: 360px)
  and (max-width: 440px) {
  * {
    --piano-key-white-height: 10em;
    --piano-key-margin: -0.56em;
    --piano-key-font-size: 14px;
    --guitar-scale: 0.4;
    --scale-view-key-padding: 0 6px;
  }
}

@media only screen
  and (min-width: 440px)
  and (max-width: 666px)
  {
  * {
    --piano-key-white-height: 12em;
    --piano-key-margin: -0.67em;
    --piano-key-font-size: 16px;
    --guitar-scale: 0.5;
    --scale-view-key-padding: 0 6px;
  }
}

@media only screen
  and (min-width: 666px)
  and (max-width: 820px)
  {
  * {
    --piano-key-white-height: 16em;
    --piano-key-margin: -0.89em;
    --guitar-scale: 0.7;
    --scale-view-key-padding: 0 6px;
  }
}

@media only screen
  and (min-width: 820px)
  and (max-width: 1000px)
  {
  * {
    --piano-key-white-height: 20em;
    --piano-key-margin: -1.12em;
    --guitar-scale: 0.8;
    --scale-view-key-padding: 3px 9px;
  }
}

@media only screen
  and (max-width: 1200px)
  {
    .App {
      background-color: #f3e3e3;
    }

    /* fix: mobile smal screens */
    body {
      background-color: #f3e3e3;
    }
}