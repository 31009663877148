* {
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto Mono', monospace; */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-white);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--color-white);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-white);
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: var(--color-white);
  font-size: var(--text-size-default);
}

p {
  font-size: var(--text-size-default);
}

h2 {
  font-size: var(--text-size-h2);
}
h3 {
  font-size: var(--text-size-h3);
}
.padding-around-s {
  padding: 0 6px;
}

.hidden {
  display: none;
}

