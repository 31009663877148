.guitar {
  transform: scale(var(--guitar-scale));
}

.guitar .guitar-container {
  display: flex;
  flex-direction: column;
  border-top: 2px solid var(--color-text);
  border-bottom: 2px solid var(--color-text);
}

.guitar .guitar-container .string {
  display: flex;
  flex-direction: row;
}

.guitar .guitar-container .string .note {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--color-brown);
  width: 4rem;
  height: 2rem;
}

.guitar .guitar-container .string .note[fret="0"] {
  border-left: 2px solid var(--color-brown);
}

.guitar .guitar-container .string .note[active=true] {
  background-color: var(--piano-color-active);
}

.guitar .guitar-container .string .note[iskey=true] {
  background-color: var(--piano-color-isKey);
}

.guitar .guitar-container .string .note p {
  text-align: center;
  flex: 1;
}

.guitar .guitar-container .string .note p {
  position: relative;
  z-index: 1;
}

.guitar .guitar-container .string .note p:before {
  border-top: 2px solid var(--color-brown);
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.guitar .guitar-container .string .note span {
  background-color: var(--color-white);
  padding: 0px 2px;
}

.guitar .guitar-container .string .note[active=true] span {
  background-color: var(--piano-color-active);
}

.guitar .guitar-container .string .note[iskey=true] span {
  background-color: var(--piano-color-isKey);
}

.guitar .fret-helper {
  display: flex;
  justify-content: space-around;
}

.guitar .fret-helper .fret-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 2rem;
}